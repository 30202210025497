import styled from "styled-components";
import { ItemControls } from "../Lists.shared.style";

export const ReleaseName = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.white};
`;

export const Artists = styled.div`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.blue[500].accent};
`;

export const ItemActions = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  left: 100%;
  transition: left 0.2s ease-in-out;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
  color: ${(props) => props.theme.colors.neutrals.primary.white};
  
  overflow-x: hidden;
  @supports (overflow-x: clip) {
    overflow-x: clip;
  }

  div.actions {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 8px;
    @media ${(props) => props.theme.device.xl} {
      justify-content: flex-start;
    }
  }

  div.cart-actions {
    margin-top: 8px;
  }
`;

export const Spacer = styled.div`
  width: 12px;
`;

export const ItemNumber = styled.span`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  width: 36px;
  min-width: 36px;
`;

export const ItemMeta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 100px);
  height: 100%;
  position: absolute;
  left: 50px;
  overflow-x: clip;
  padding-right: 1rem;
`;

export const ItemButtons = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  width: 40px;
  border-left: 1px solid ${(props) => props.theme.colors.neutrals.primary[800]};
  background: ${(props) => props.theme.colors.neutrals.primary[600]};
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background: ${(props) => props.theme.colors.neutrals.primary[600]};

  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;

  color: ${(props) => props.theme.colors.neutrals.primary.graytext};

  &.actions ${ItemActions} {
    left: 0;
  }

  .play-hover {
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 36px;
    min-width: 36px;
  }

  &.no-artwork {
    ${ItemMeta} {
      left: 0;
      width: calc(100% - 50px);
    }
  }

  &.force-hover {
    span.play {
      display: none;
    }

    ${ItemMeta} {
      width: calc(100% - 50px);
    }

    ${ItemControls} {
      display: none;
      width: calc(100% - 50px);
    }

    ${ItemActions} {
      padding-left: 36px;
    }

    &:hover {
      .play-hover {
        display: flex;
      }

      ${ItemNumber} {
        display: none;
      }

      ${ItemActions} {
        justify-content: flex-start;
        left: 89px;
        @media ${(props) => props.theme.device.xxl} {
          left: 128px;
        }
      }
    }
  }

  &.force-hover.no-artwork {
    ${ItemMeta} {
      left: 0;
      width: calc(100% - 10px);
    }

    ${ItemActions} {
      padding-left: 4px;
    }

    &:hover {
      ${ItemActions} {
        justify-content: flex-start;
        left: 120px;
        @media ${(props) => props.theme.device.xxl} {
          left: 200px;
        }
      }
    }
  }

  &.cart-list {
    ${ItemActions} {
      gap: 0px;
      justify-content: flex-end;
      div.actions {
        justify-content: center;
      }

      div.cart-actions {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  &.row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
    gap: 8px;
    padding: 16px 0;
    width: 100%;

    &.panel-list {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }

    @media ${(props) => props.theme.device.lg} {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
  }

  &.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    padding: 16px 0;
  }
`;
