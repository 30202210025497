import Marquee from "@components/Marquee";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import LabelLink from "@components/shared/Labels/LabelLink";
import { cls } from "@lib/css";
import { CartItem } from "@models/Cart";
import { Audioformat } from "@models/audio-format";
import { Release } from "@models/release";
import { dataLayerReleaseImpression, pushProductClickEvent, pushProductImpressionEvent } from "@utils/dataLayer";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";

import { Artwork, CartMeta, Exclusive, ItemControls, MetaRow, MoreButton } from "../Lists.shared.style";
import {
	Artists,
	Item,
	ItemActions,
	ItemButtons,
	ItemMeta,
	ItemNumber,
	ReleaseName,
	Spacer,
	Wrapper,
} from "./ReleasesList.style";

import { renderArtistNames } from "@components/shared/Artists/ArtistNames";
import { renderAudioFormatName } from "@lib/constants/audio-format";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { Trans, useTranslation } from "next-i18next";

interface Props {
	isLoading: boolean;
	location?: string;
	releases?: Release[];
	showNumbers?: boolean;
	direction?: "row" | "column";
	showArtwork?: boolean;
	forceHover?: boolean;
	isPanelList?: boolean;
	cartReleases?: CartItem<Release>[];
	itemCartActions?: (cartItem: CartItem<Release>) => JSX.Element;
	selectedItems?: number[];
	onItemSelectionChange?: (itemId: number, selected: boolean) => void;
	audioFormats?: Record<number, Audioformat>;
	dataTestId?: string;
}

const ReleasesList: React.FC<Props> = ({
	// isLoading, // TODO: Decide if we should use this prop or remove it everywhere
	location,
	releases = [],
	showNumbers,
	direction = "row",
	showArtwork,
	forceHover,
	isPanelList,
	cartReleases,
	itemCartActions,
	selectedItems,
	onItemSelectionChange,
	audioFormats = {},
	dataTestId,
}) => {
	const [controls, setControls] = useState<number | undefined>();
	const { t } = useTranslation("translation");
	const exclusive = `${t("Exclusive")}`;

	const filteredReleases = cartReleases ?
		cartReleases
			.filter((r) => r.item !== undefined)
			.map((c) => {
				c.item.cart_item_data = c;
				return c.item;
			}) :
		releases;

	const getProductEventData = useCallback(dataLayerReleaseImpression, [location]);

	useEffect(() => {
		if (filteredReleases && filteredReleases.length > 0) {
			pushProductImpressionEvent({
				ecommerce: {
					currencyCode: filteredReleases[0]?.price?.code || "",
					impressions: filteredReleases.map((release, index) => getProductEventData({ release, index, location })),
				},
			});
		}
	}, []);

	const isCartList = cartReleases !== undefined;

	const handleReleaseClick = (release: Release, index: number) => {
		pushProductClickEvent({
			ecommerce: {
				currencyCode: release?.price?.code || "",
				click: {
					actionField: {
						list: location || "",
					},
					products: [getProductEventData({ release, index, location })],
				},
			},
		});
	};

	return (
		<Wrapper className={cls(direction, isPanelList ? "panel-list" : undefined)}>
			{filteredReleases.map((release, index) => (
				<Item
					key={`list-release-${release.id}`}
					className={cls(
						controls && controls === release.id ? "actions" : undefined,
						!showArtwork ? "no-artwork" : undefined,
						forceHover ? "force-hover" : undefined,
						isCartList ? "cart-list" : undefined,
					)}
					data-testid={dataTestId}
				>
					{showArtwork && (
						<Link
							href={`/release/${release.slug}/${release.id}`}
							prefetch={false}
							title={release.name}
							className="artwork"
							onClick={() => handleReleaseClick(release, index)}
						>
							<Artwork $withTrim={true}>
								<Image
									src={dynamicImageUrl({ imageUri: release.image?.uri, size: "sm" })}
									alt={release.name}
									width={50}
									height={80}
								/>
								{release.exclusive && <Exclusive>{exclusive.toUpperCase()}</Exclusive>}
							</Artwork>
						</Link>
					)}

					<ItemMeta>
						{!isCartList && showNumbers ?
								(
									<>
										<ItemNumber data-testid="track-number">{index + 1}</ItemNumber>
										<div className="play-hover">
											<Play releaseId={release.id} />
										</div>
									</>
								) :
								(
									<Spacer />
								)}
						{isCartList && release.cart_item_data && onItemSelectionChange && (
							<ItemNumber
								onClick={() => {
									onItemSelectionChange(
										release.cart_item_data?.id || 0,
										!selectedItems?.some((i) => i === release.cart_item_data?.id),
									);
								}}
							>
								{selectedItems?.some((i) => i === release.cart_item_data?.id) ?
										(
											<SpriteIcon id="checkbox-selected" width="20" height="20" />
										) :
										(
											<SpriteIcon id="checkbox" width="20" height="20" />
										)}
							</ItemNumber>
						)}
						<MetaRow>
							<Link
								href={`/release/${release.slug}/${release.id}`}
								prefetch={false}
								title={release.name}
								onClick={() => handleReleaseClick(release, index)}
							>
								<Marquee overlayElementSelector={`[data-overlayfor="release-${release.id}"]`} shouldWrap={false}>
									<ReleaseName>{release.name} </ReleaseName>
								</Marquee>
							</Link>
							{release.artists && release.artists.length > 0 && (
								<Marquee overlayElementSelector={`[data-overlayfor="release-${release.id}"]`} shouldWrap={false}>
									<Artists>
										{renderArtistNames(release.artists, { location })}
									</Artists>
								</Marquee>
							)}
							{!isCartList && (
								<Marquee overlayElementSelector={`[data-overlayfor="release-${release.id}"]`} shouldWrap={false}>
									<LabelLink label={release.label} location={location} withMarquee shouldMarqueeWrap={false} />
								</Marquee>
							)}
							{isCartList && (
								<CartMeta>
									<span>{release.cart_item_data?.price?.total.display}</span>
									<span>
										<Trans>
											{renderAudioFormatName(
												{ audioFormats: Object.values(audioFormats), audioFormatId: release.cart_item_data?.audio_format_id, purchaseTypeId: undefined, item: release },
											)}
										</Trans>
									</span>
								</CartMeta>
							)}
						</MetaRow>
						<ItemActions data-overlayfor={`release-${release.id}`}>
							<div className="actions">
								<span className="play">
									<Play releaseId={release.id} />
								</span>
								<span className="queue">
									<AddToQueue releaseId={release.id} />
								</span>
								<span className="playlist">
									<AddToPlaylist releaseId={release.id} />
								</span>
								{!isCartList && (
									<span className="card">
										<AddToCart location={location} release={release} />
									</span>
								)}
							</div>
							{isCartList && itemCartActions && release.cart_item_data && (
								<div className="cart-actions">{itemCartActions(release.cart_item_data)}</div>
							)}
						</ItemActions>
					</ItemMeta>
					<ItemControls>
						<ItemButtons>
							<MoreButton>
								<Play releaseId={release.id} />
							</MoreButton>
							<MoreButton
								onClick={() => {
									controls === release.id ? setControls(undefined) : setControls(release.id);
								}}
							>
								<SpriteIcon id="dots" width="32" height="32" />
							</MoreButton>
						</ItemButtons>
					</ItemControls>
				</Item>
			))}
		</Wrapper>
	);
};

export default ReleasesList;
